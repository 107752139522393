import { Heading, StyledHomePage, StyledMenu } from './homeStyle';
import { Toggle } from './toggle';

export const Home = () => {
  return (
    <StyledHomePage>
      <Heading>Home Page</Heading>
    </StyledHomePage>
  );
};
